// _variables.scss
$brandFontFamily: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
$readableFontFamily: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

$primary: #F5F749;
$secondary: #466AA4;
$third: #F24236;

$success: #4FBA62;
$danger: #D24128;
$warning: #faaa35;
$pink: #F4ABBA;
$green: #4FBA62;
$blue: #5BA5E6;
$orange: #faaa35;
$dark-soft: #8899A6;
$dark: #424E57;

$g: #070809;
$gs10: lighten($g, 100%);
$gs9: lighten($g, 90%);
$gs8: lighten($g, 80%);
$gs7: lighten($g, 70%);
$gs6: lighten($g, 60%);
$gs5: lighten($g, 50%);
$gs4: lighten($g, 40%);
$gs3: lighten($g, 30%);
$gs2: lighten($g, 20%);
$gs1: lighten($g, 10%);
$gs0: $g;

$commonRadius: 2px;