@import '../../styles/_variables.scss';

.field{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;

  &.no-margin-top{
    margin-top: 0px;
  }

  &.no-margin{
    margin: 0px;
  }

  .field-label{
    // opacity: 1;
    font-size: 16px;
    transition: opacity 200ms ease-in-out;
    text-transform: capitalize;
    font-weight: 700;
    &.field-label-disabled{
      opacity: .75;
    }
    &.field-label-hidden{
      opacity: 0;
    }
  }

  .field-error{
    height: 12px;
    font-size: 12px;
    text-align: right;
    color: $danger;
  }
  .field-description{
    height: 12px;
    font-size: 12px;
    text-align: right;
    color: $gs4;
    &.field-description-disabled{
      opacity: .75;
    }
  }

  textarea{
    resize: none;
    padding-top: 6px;
    min-height: 42px;
    width: 100%;
  }

  .field-icon{
    position: absolute;
    top: 14px;
    right: 13px;
    color: $gs7;
    .clickable{
      &:hover{
        color: $secondary;
      }
    }
  }

  .field-icon-inline{
    position: absolute;
    top: 10px;
    right: 7px;
    
    .clickable{
      &:hover{
        color: $secondary;
      }
    }
  }

  .field-tooltip{
    pointer-events: none;
    position: absolute;
    left: 0px;
    top: 40px;
    z-index: 1000;
    max-width: 200px;
    background: $gs1;
    padding: 10px;
    font-weight: 700;
    // border: 2px solid $gs5;
    border-radius: 5px;
    opacity: .98;
    color: $gs9;
    box-shadow: 0px 1px 3px rgba(0,0,0,.1);
  }

  .input{

    margin-top: 2px;
    margin-bottom: 2px;
    padding: 10px;
    font-size: 16px;
    height: auto;
    border-radius: 3px;
    border: 2px solid $gs8;
    overflow: hidden;
    width: 100%;

    &.input-jumbo{
      font-size: 50px;
      border: none;
      font-weight: 700;
      background: none;
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #000;
        opacity: .5;
        // font-weight: 300;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: #000;
        opacity: .5;
        // font-weight: 300;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: #000;
        opacity: .5;
        // font-weight: 300;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: #000;
        opacity: .5;
        // font-weight: 300;
      }
    }

    &.input-inline{
      // font-size: 14px;
      // border: none;
      height: 38px;
      margin: 0px;
      // font-weight: 700;
      // background: none;
      // width: 100%;
    }

    &.input-error{
      border-color: $danger !important;
    }

    &:disabled{
      pointer-events: none;
      opacity: .9;
      background: $gs9;
    }

    &:focus{
      outline: none;
      border-color: $blue;
      background: $gs10;    
      &.input-error{
        border-color: $danger;
      }
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $gs5;
      // font-weight: 300;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $gs5;
      // font-weight: 300;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $gs5;
      // font-weight: 300;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $gs5;
      // font-weight: 300;
    }
  }
}