.header-bumper {
  height: 75px;
  display: block;
}

.header {
  background: transparent;
  height: 75px;
  padding-left: 20px;
  padding-right: 20px;
  pointer-events: none;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 999;
  transition: height 400ms ease-in-out, padding-left 400ms ease-in-out, padding-right 400ms ease-in-out;
}
.header .menu-btn {
  pointer-events: auto;
  cursor: pointer;
}
.header .menu-btn:hover {
  color: #F24236;
}
.header .brand {
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  pointer-events: auto;
}
.header .hidden-top {
  opacity: 1;
  transition: opacity 400ms ease-in-out;
}
.header .flex-split {
  height: 100%;
}
.header.header-top {
  height: 75px;
  padding-left: 20px;
  padding-right: 20px;
}
.header.header-top a {
  pointer-events: auto;
  color: #070809;
}
.header.header-top a:hover {
  color: #070809;
}
.header.header-top .hidden-top {
  opacity: 0;
  pointer-events: none;
}
.header.header-scrolling a {
  pointer-events: auto;
  color: #070809;
}
.header.header-scrolling a:hover {
  color: #070809;
}

