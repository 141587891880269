#sidemenu {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 300px;
  max-width: 100vw;
  padding-top: 75px;
  padding-right: 0px;
  font-size: 22px;
  transition: right ease-in-out 400ms, padding-top 400ms ease-in-out, padding-right 400ms ease-in-out, font-size 400ms ease-in-out;
  color: #1d2226;
  z-index: 1000;
  opacity: 1;
  overflow-y: auto;
  overflow-x: hidden;
  pointer-events: none;
}
#sidemenu.sidemenu-open {
  right: 0px;
}
#sidemenu.sidemenu-closed {
  right: -305px;
  font-size: 16px;
}
#sidemenu .sidemenu-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
#sidemenu .sidemenu-item {
  font-weight: 300;
  padding-left: 15px;
  padding-right: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: font-size 400ms ease-in-out;
  margin: 5px;
  margin-right: -5px;
  display: block;
  background: white;
}
#sidemenu .sidemenu-item.sidemenu-item-emphasized {
  font-weight: 700;
}
#sidemenu .sidemenu-footer {
  margin-top: 100px;
  margin-bottom: 40px;
}
#sidemenu .sidemenu-exit-btn {
  cursor: pointer;
  position: absolute;
  top: 13px;
  right: 25px;
  opacity: 0.3;
}
#sidemenu .sidemenu-exit-btn:hover {
  color: #F5F749;
  opacity: 1;
}
#sidemenu a {
  pointer-events: auto;
}
#sidemenu a.sidemenu-link {
  color: #1d2226;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  text-decoration: none;
  border: 4px solid transparent;
  border-radius: 2px;
  transition: none;
  border-color: #070809;
}
#sidemenu a.sidemenu-link:hover {
  color: white;
  background: #070809;
  border-color: #070809;
}

