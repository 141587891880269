.pc-tooltip-wrapper {
  position: absolute;
  z-index: 9999;
  pointer-events: none;
  overflow: hidden;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.pc-tooltip-wrapper .pc-tooltip-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.pc-tooltip {
  position: absolute;
  opacity: 0;
  transition: opacity 150ms ease-out, margin-top 250ms ease-out, margin-bottom 250ms ease-out, margin-left 250ms ease-out, margin-right 250ms ease-out;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
.pc-tooltip.pc-tooltip-top {
  margin-bottom: -20px;
}
.pc-tooltip.pc-tooltip-bottom {
  margin-top: -20px;
}
.pc-tooltip.pc-tooltip-left {
  margin-right: -20px;
}
.pc-tooltip.pc-tooltip-right {
  margin-left: -20px;
}
.pc-tooltip.pc-tooltip-show {
  opacity: 1;
  transition: opacity 250ms ease-out, margin-top 250ms ease-out, margin-bottom 250ms ease-out, margin-left 250ms ease-out, margin-right 250ms ease-out;
}
.pc-tooltip.pc-tooltip-show.pc-tooltip-top {
  margin-bottom: 0px;
}
.pc-tooltip.pc-tooltip-show.pc-tooltip-bottom {
  margin-top: 0px;
}
.pc-tooltip.pc-tooltip-show.pc-tooltip-left {
  margin-right: 0px;
}
.pc-tooltip.pc-tooltip-show.pc-tooltip-right {
  margin-left: 0px;
}
.pc-tooltip .pc-tooltip-content {
  position: relative;
  display: inline-block;
  background: white;
  color: #1d2226;
  font-size: 13px;
  margin: 0;
  padding: 10px;
  padding-left: 13px;
  padding-right: 13px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  border: 1px solid #b2bbc3;
}
.pc-tooltip .pc-tooltip-content .tooltip-title {
  font-size: 14px;
  font-weight: 700;
}
.pc-tooltip .pc-tooltip-content.pc-tooltip-content-top {
  margin-left: -50%;
}
.pc-tooltip .pc-tooltip-content.pc-tooltip-content-top:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #6b7b8a;
  bottom: -7px;
  left: 50%;
  margin-left: -7px;
}
.pc-tooltip .pc-tooltip-content.pc-tooltip-content-top:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid white;
  bottom: -6px;
  left: 50%;
  margin-left: -6px;
}
.pc-tooltip .pc-tooltip-content.pc-tooltip-content-bottom {
  margin-left: -50%;
}
.pc-tooltip .pc-tooltip-content.pc-tooltip-content-bottom:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #6b7b8a;
  top: -7px;
  left: 50%;
  margin-left: -7px;
}
.pc-tooltip .pc-tooltip-content.pc-tooltip-content-bottom:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  top: -6px;
  left: 50%;
  margin-left: -6px;
}
.pc-tooltip .pc-tooltip-content.pc-tooltip-content-left {
  margin-left: -100%;
  margin-top: -50%;
}
.pc-tooltip .pc-tooltip-content.pc-tooltip-content-left:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #6b7b8a;
  right: -7px;
  top: 50%;
  margin-top: -7px;
}
.pc-tooltip .pc-tooltip-content.pc-tooltip-content-left:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid white;
  right: -6px;
  top: 50%;
  margin-top: -6px;
}
.pc-tooltip .pc-tooltip-content.pc-tooltip-content-right {
  margin-top: -50%;
}
.pc-tooltip .pc-tooltip-content.pc-tooltip-content-right:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid #6b7b8a;
  left: -7px;
  top: 50%;
  margin-top: -7px;
}
.pc-tooltip .pc-tooltip-content.pc-tooltip-content-right:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid white;
  left: -6px;
  top: 50%;
  margin-top: -6px;
}

