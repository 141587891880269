@import '../../styles/_variables.scss';

$border-radius: 5px;

.button{
  position: relative;
  text-decoration: none !important;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: none;

  .thinking{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  

  .button-wrapper{
    // height: 100%;
    cursor: pointer;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    white-space: nowrap;

    &.button-space-between{
      justify-content: space-between !important;
    }
    .button-icon{
      margin-right: -10px;
      opacity: .5;
    }
    .button-display{
      position: relative;
      // top: -1px;
    }
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 1px;
    border-radius: $border-radius;// 20px;
    &.button-dropdown{
      padding-right: 10px;
      padding-left: 13px;
      border-radius: $border-radius 0px 0px $border-radius;// 20px 0px 0px 20px;
    }
  }
  .button-dropdown-arrow{
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 25px;
    border-radius: 0px $border-radius $border-radius 0px;// 0px 20px 20px 0px; 
    padding-right: 2px;
    border-left: 1px solid transparentize($gs9,.5);
    // margin-left: 10px;
    // background-color: #F00;
    height: 100%;
    border-bottom: none !important;
  }
  
  .dropdown-menu{
    // width: 300px;
    border-radius: $border-radius;// 10px;
    .dropdown-item{
      &:hover{
        .translucent{
          opacity: 1;
        }
      }
    }
  }

  .dropdown-toggle::after {
    display:none;
  }

  &.fixed-width{
    width: 175px;
  }

  &:hover{
    text-decoration: none !important;
  }

  // color: $gs0;
  // background: $gs9;
  // &:hover{
  //   background: $gs8;
  //   color: $primary;
  // }
  // &:active{
  //   background: $gs7;
  // }


  
  user-select: none;

  border-radius: $border-radius;// 5px;
  
  text-decoration: none;
  font-weight: 700;
  font-size: 13px;

  // color: $gs3;

  // padding-top: 6px;
  // padding-left: 15px;
  // padding-right: 15px;

  .button-thin{
    height: 30px;
  }

  .fa-fw{
    position: relative;
    // top: 1px;
  }

  .button-sm{
    font-size: 10px;
    height: 25px;
  }

  .button-md{
    font-size: 16px;
    border-radius: $border-radius;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .button-lg{
    font-size: 20px;
    height: 50px;
    padding-top: -1px;
    padding-left: 25px;
    padding-right: 25px;

    border-radius: $border-radius;
    
    &.fixed-width{
      width: 175px;
    }
  }



  &:hover{
    text-decoration: none;
  }
  &:active{
    text-decoration: none;
  }
  
  .button-wrapper{
    &.button-no-click{
      pointer-events: none;
    }
  }

  .button-wrapper, .button-dropdown{

    color: $gs0;
    border: 3px solid $gs0;
    background: transparent;

    transition: background 100ms ease-in-out, color 100ms ease-in-out;

    &:hover{
      background: $gs0;
      color: $gs10;
    }


    &.button-success{
      color: $gs10;  
      background-color: $success;  

      &:hover{
        color: $gs10;  
        background-color: darken($success, 10%);
      }
      &:active{
        color: $gs10;  
        background-color: darken($success, 20%);
      }
    }

    &.button-danger{
      color: $gs10;  
      background-color: $danger;  

      &:hover{
        color: $gs10;  
        background-color: darken($danger, 10%);
      }
      &:active{
        color: $gs10;  
        background-color: darken($danger, 20%);
      }
    }

    &.button-primary{
      color: $gs10;  
      background-color: $primary;  

      &:hover{
        color: $gs10;  
        background-color: darken($primary, 10%);
      }
      &:active{
        color: $gs10;  
        background-color: darken($primary, 20%);
      }
    }

    &.button-gs5{
      color: $gs1;  
      background-color: $gs5;  

      &:hover{
        color: $gs10;  
        background-color: darken($gs5, 10%);
      }
      &:active{
        color: $gs10;  
        background-color: darken($gs5, 20%);
      }
    }

    &.button-secondary{
      color: $gs10;  
      background-color: $secondary;  

      &:hover{
        color: $gs10;  
        background-color: darken($secondary, 10%);
      }
      &:active{
        color: $gs10;  
        background-color: darken($secondary, 20%);
      }
    }

    &.button-hover-text-success{
      &:hover{
        color: $success !important;
      }
    }

    &.button-hover-text-primary{
      &:hover{
        color: $primary !important;
      }
    }

    &.button-hover-text-secondary{
      &:hover{
        color: $secondary !important;
      }
    }

    &.button-warning{
      color: $gs10;  
      background-color: $warning;  

      &:hover{
        color: $gs10;  
        background-color: darken($warning, 10%);
      }
      &:active{
        color: $gs10;  
        background-color: darken($warning, 20%);
      }
    }

    &.button-orange{
      color: $gs10;  
      background-color: $orange;  

      &:hover{
        color: $gs10;  
        background-color: darken($orange, 10%);
      }
      &:active{
        color: $gs10;  
        background-color: darken($orange, 20%);
      }
    }
    
    &.button-white{
      color: $gs2;  
      background-color: $gs10;  

      &:hover{
        color: $secondary;  
        background-color: $gs10;
      }
      &:active{
        color: darken($secondary, 20%);  
        background-color: $gs10;
      }
    }
  }

  &.no-pad-right{
    padding-right: 0px !important;
  }


  &.button-disabled{
    opacity: .5;
    pointer-events: none !important;
  }


  &.button-thinking{
    opacity: .75;
    pointer-events: none;
  }

  .dropdown{
    position: inherit;
    .dropdown-menu{
      margin-top: -2px;
      position: inherit;
      z-index: 1000;
      width: 300px;
      border-radius: 10px 0px 10px 10px;
      .dropdown-item{
        &:hover{
          .translucent{
            opacity: 1;
          }
        }
      }
    }
  }
  .dropdown-toggle::after {
    display:none;
  }
}