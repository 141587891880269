.sketch-manager {
  position: relative;
  width: 100%;
  height: 100%;
}
.sketch-manager .sketch-manager-inner {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
}

