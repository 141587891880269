@import '../../styles/_variables.scss';

.checkbox{
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  position: relative;
  top: 1px;
  padding-right: 0px;
  padding-left: 0px;
  width: 28px;
  user-select: none;
  margin-right: 5px;

  &.disabled{
    cursor: auto;
  }
  
  &.checkbox-true{
    color: $success;
  }
  &.checkbox-false{
    color: $gs7;
  }
}