@import '../../styles/_variables.scss';

$sidemenu-width: 300px;

#sidemenu{
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: $sidemenu-width;
  max-width: 100vw;

  padding-top: 75px;
  padding-right: 0px;
  font-size: 22px;

  transition: right ease-in-out 400ms, padding-top 400ms ease-in-out, padding-right 400ms ease-in-out, font-size 400ms ease-in-out;

  color: $gs1;

  z-index: 1000;
  opacity: 1;
  overflow-y: auto;
  overflow-x: hidden;
  pointer-events: none;

  &.sidemenu-open{
    right: 0px;
  }

  &.sidemenu-closed{
    right: -$sidemenu-width - 5px;
    font-size: 16px;
    .sidemenu-item{

      
    }
  }

  &.sidemenu-top{
    .sidemenu-item{

    }
  }

  .sidemenu-list{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
  
  .sidemenu-item{
    font-weight: 300;
    padding-left: 15px;
    padding-right: 50px;
    padding-top: 5px;
    padding-bottom: 5px;

    transition: font-size 400ms ease-in-out;
    
    margin: 5px;
    margin-right: -5px;
    // font-size: 16px;
    display: block;
    background: $gs10;

    &.sidemenu-item-emphasized{
      font-weight: 700;
    }
  }

  .sidemenu-footer{
    margin-top: 100px;
    margin-bottom: 40px;
  }

  .sidemenu-exit-btn{
    cursor: pointer;
    position: absolute;
    top: 13px;
    right: 25px;
    opacity: .3;
    &:hover{
      color: $primary;
      opacity: 1;
    }
  }

  a{
    pointer-events: auto;
    &.sidemenu-link{
      color: $gs1;
      font-family: $readableFontFamily;
      font-weight: 400;
      text-decoration: none;
      border: 4px solid transparent;
      border-radius: $commonRadius;
      transition: none;
      border-color: $gs0;

      &:hover{
        color: $gs10;
        background: $gs0;
        border-color: $gs0;
      }
    }
  }
}