.field {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}
.field.no-margin-top {
  margin-top: 0px;
}
.field.no-margin {
  margin: 0px;
}
.field .field-label {
  font-size: 16px;
  transition: opacity 200ms ease-in-out;
  text-transform: capitalize;
  font-weight: 700;
}
.field .field-label.field-label-disabled {
  opacity: 0.75;
}
.field .field-label.field-label-hidden {
  opacity: 0;
}
.field .field-error {
  height: 12px;
  font-size: 12px;
  text-align: right;
  color: #D24128;
}
.field .field-description {
  height: 12px;
  font-size: 12px;
  text-align: right;
  color: #606e7c;
}
.field .field-description.field-description-disabled {
  opacity: 0.75;
}
.field textarea {
  resize: none;
  padding-top: 6px;
  min-height: 42px;
  width: 100%;
}
.field .field-icon {
  position: absolute;
  top: 14px;
  right: 13px;
  color: #b2bbc3;
}
.field .field-icon .clickable:hover {
  color: #466AA4;
}
.field .field-icon-inline {
  position: absolute;
  top: 10px;
  right: 7px;
}
.field .field-icon-inline .clickable:hover {
  color: #466AA4;
}
.field .field-tooltip {
  pointer-events: none;
  position: absolute;
  left: 0px;
  top: 40px;
  z-index: 1000;
  max-width: 200px;
  background: #1d2226;
  padding: 10px;
  font-weight: 700;
  border-radius: 5px;
  opacity: 0.98;
  color: #ebeef0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}
.field .input {
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 10px;
  font-size: 16px;
  height: auto;
  border-radius: 3px;
  border: 2px solid #cfd4d9;
  overflow: hidden;
  width: 100%;
}
.field .input.input-jumbo {
  font-size: 50px;
  border: none;
  font-weight: 700;
  background: none;
}
.field .input.input-jumbo::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #000;
  opacity: 0.5;
}
.field .input.input-jumbo::-moz-placeholder { /* Firefox 19+ */
  color: #000;
  opacity: 0.5;
}
.field .input.input-jumbo:-ms-input-placeholder { /* IE 10+ */
  color: #000;
  opacity: 0.5;
}
.field .input.input-jumbo:-moz-placeholder { /* Firefox 18- */
  color: #000;
  opacity: 0.5;
}
.field .input.input-inline {
  height: 38px;
  margin: 0px;
}
.field .input.input-error {
  border-color: #D24128 !important;
}
.field .input:disabled {
  pointer-events: none;
  opacity: 0.9;
  background: #ebeef0;
}
.field .input:focus {
  outline: none;
  border-color: #5BA5E6;
  background: white;
}
.field .input:focus.input-error {
  border-color: #D24128;
}
.field .input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #798896;
}
.field .input::-moz-placeholder { /* Firefox 19+ */
  color: #798896;
}
.field .input:-ms-input-placeholder { /* IE 10+ */
  color: #798896;
}
.field .input:-moz-placeholder { /* Firefox 18- */
  color: #798896;
}

