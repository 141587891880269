@import '../../styles/_variables.scss';

.footer{
  pointer-events: none;
}
.footer-wrapper{
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 10px;
  padding-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  pointer-events: none;

  p{
    margin-top: 5px;
    margin-bottom: 0px;
  }

  a{
    pointer-events: auto;
  }

  .footer-links{
    
    font-size: 16px;
    color: $gs6;
    a{
      pointer-events: auto;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .footer-group{
    display: flex;
    flex-direction: row;
  
    .footer-item{
      padding-left: 10px;
      padding-right: 10px;
      font-size: 14px;
    }
  }
}
