@import '../../styles/_variables.scss';

.header-bumper{
  height: 75px;
  display: block;
  // margin-bottom: 50px;
}

.header{
  background: transparent;
  height: 75px;
  padding-left: 20px;
  padding-right: 20px;

  pointer-events: none;

  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 999;

  transition: height 400ms ease-in-out, padding-left 400ms ease-in-out, padding-right 400ms ease-in-out;

  .menu-btn{
    pointer-events: auto;
    cursor: pointer;
    &:hover{
      color: $third;
    }
  }

  .brand{
    font-size: 20px;
    font-family: $brandFontFamily;
    pointer-events: auto;
  }

  .hidden-top{
    opacity: 1;
    transition: opacity 400ms ease-in-out;
  }

  .flex-split{
    height: 100%;
  }
  
  &.header-top{
    height: 75px;
    padding-left: 20px;
    padding-right: 20px;
    a{
      pointer-events: auto;
      color: $gs0;
      &:hover{
        color: $gs0;
        // border-color: $gs0;
      }
    }

    .hidden-top{
      opacity: 0;
      pointer-events: none;
    }
  }


  &.header-scrolling{
    a{
      pointer-events: auto;
      color: $gs0;
      &:hover{
        color: $gs0;
        // border-color: $secondary;
      }
    }
  }

}
