@import './_variables.scss';


html,body{
  height: 100%;
  margin: 0;
  padding: 0;
  background: $gs10;
  font-variant-ligatures: no-common-ligatures;
  -webkit-font-variant-ligatures: no-common-ligatures;
  font-family: $readableFontFamily;
  font-weight: 500;
  // font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root{
  height: 100%;
  >div{
    position: relative;
    min-height: 100%;
  }
}

.body{
  height: 100%;
  .body-wrapper{
    min-height: 100%;
    display: flex;
    flex-direction: column;
    .body-stretch{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
    }
  }
}

.body-wrapper{
  height: 100%;
}


h1,h2,h3,h4,h5{
  user-select: none;
  font-family: $brandFontFamily;
}
h5{
  font-weight: 700;
}

h1{
  font-size: 3.5rem;
  font-weight: 700;
}

.font-size-50-vw{
  // font-size: 5vw;
}

a{
  color: $gs0;
  font-weight: 700;
  text-decoration: none !important;
  transition: color 200ms ease-in-out;
  cursor: pointer;
  // border-bottom: 4px solid transparent;
  &:hover{
    color: $gs0;
    // border-bottom: 4px solid $primary;
  }
  &.no-color{
    color: inherit;
  }

}

.clickable{
  cursor: pointer;
}

.oscilate-vertical{
  position: relative;
  animation: oscilate-vertical 1.5s infinite;
}

$oscilate-size: 2px;
@keyframes oscilate-vertical{
  0%{
    top: -$oscilate-size;
  }
  50%{
    top: $oscilate-size;
  }
  100%{
    top: -$oscilate-size;
  }
}


.jumbotron{
  background: transparent;
  margin-bottom: 0px;
  position: relative;
  border-radius: 20px;

  .jumbotron-popout{
    position: absolute;
    top: -100px;
    background: $gs10;
    border-radius: 2px;
    padding: 40px;
    box-shadow: 0 3px 6px rgba(0,0,0,.1);
    color: $gs1;
  }

  .jumbotron-header-verbs{
    font-size: 11px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  &.jumbotron-mega-pad{
    padding-top: 125px;
    padding-bottom: 125px;
  }

  h1{
    // font-size: 5em;
    margin-bottom: 30px;
  }
  h3{
    margin-bottom: 10px;
  }

  &.jumbotron-white{
    background: $gs9;
  }

  &.jumbotron-clear{
    background: transparent;
  }

  &.jumbotron-primary{
    background: $primary;
    color: $gs10;
  }

  &.jumbotron-secondary{
    background: $secondary;
    color: $gs10;
  }

  &.jumbotron-light{
    background: $gs9;
  }
}

.text-heavy{
  font-weight: 700;
}
.text-primary{
  color: $primary;
}
.text-secondary{
  color: $secondary;
}

.text-lg{
  font-size: 130%;
}
.text-sm{
  font-size: 70%;
}

.text-300{
  font-weight: 300;
}
.text-700{
  font-weight: 700;
}
.text-900{
  font-weight: 900;
}

.text-medium-body{
  font-size: 13px;
  // color: $gs0;
  font-weight: 400;
}

.translucent-hover{
  transition: opacity 200ms ease-in-out;
  opacity: 1;
  &:hover{
    opacity: .5;
  }
}
.translucent{
  opacity: .5;
}

.semi-translucent{
  opacity: .85;
}

.vertical-divider{
  font-size: 12px;
  opacity: .5;
  position: relative;
  top: -2px;
  padding-left: 5px;
  padding-right: 5px;
}

.screen-height-container{
  position: relative;
  display: block;
  width: 100%;
  min-height: 100vh;

  &.screen-height-container-with-peek{
    min-height: calc(100vh - 20px) !important;
  }
  
  .shc-background{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;


    &.shc-background-muted{
      // background: $gs0;
      >*{
        // opacity: .75;
        filter: blur(10px);
      }
    }


    .shc-carousel{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      background-attachment: fixed;
      opacity: 0;
      transition: opacity 2000ms ease-in-out;//, background-position 5000ms linear;

      &.shc-carousel-current{
        opacity: 1;
      }
    }

    .shc-background-gradient{
      position: absolute !important;
      top: 0px !important;
      height: 30% !important;
      width: 100% !important;
      min-height: 90px !important;
      background-image: linear-gradient(to bottom, transparentize(#000,.5), transparentize(#000,1)) !important;
    }
  }

  .shc-content{
    position: relative;
    z-index: 10;
    height: 100vh;
    display: block;
    &.shc-content-center{
      // height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }


  .shc-top-left-button{
    position: absolute;
    top: 20px;
    left: 20px;
  }
  .shc-top-right-button{
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .shc-content-split{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    /* Smartphones (portrait) ----------- */
    @media only screen and (max-width : 720px) {
      display: block;
    }

    .shc-content-half{
      flex: .5;
      position: relative;
    }
    
  }

  .shc-bottom-content{
    position: absolute;
    bottom: 0px;
    left: 0px; 
    right: 0px;
    z-index: 11;
    pointer-events: none;
  }

  .shc-top-center-progress{
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    pointer-events: none;

    display: flex;
    justify-content: space-around;

    padding-top: 28px;

    .fa-circle{
      color: $primary;
      margin-left: 10px;
      margin-right: 10px;
      font-size: 14px;
    }
  }
}

p{
  &.push-down{
    margin-bottom: 30px;
  }
}

.shc-padded{
  padding-top: 50px;
  padding-bottom: 50px;
}

.shc-padded-top{
  padding-top: 50px;
}

.shc-transparent{
  background: none;
}
.shc-primary{
  color: $gs10;
  background: $primary;
}
.shc-secondary{
  color: $gs10;
  background: $secondary;
}
.shc-third{
  color: $gs10;
  background: $third;
}
.shc-success{
  color: $gs10;
  background: $success;
}
.shc-gs1{
  color: $gs10;
  background: $gs1;
}
.shc-grey{
  color: $gs1;
  background: $gs7;
}
.shc-gs10{
  color: $gs1;
  background: $gs10;
}

.page-section{
  padding-top: 50px;
  padding-bottom: 50px;
}

.bg-primary-light{
  background: lighten($primary, 40%);
}

.bg-white-home-text{
  background: rgba(255,255,255,.9) !important;
  padding: 8px 10px;

  border-radius: 2px;
}

.bg-primary{
  background: $primary !important;
}

.bg-secondary{
  background: $secondary !important;
}

.flex-split{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-around{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-center-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.list-left{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .list-item-grow{
    flex-grow: 1;
  }
  .list-item{
    margin-right: 20px;
    &:last-child{
      margin-right: 0px;
    }
  }
}

.list-right{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  .list-item{
    margin-left: 20px;
    &:first-child{
      margin-left: 0px;
    }
  }
}

.rounded-image{
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 2px 2px rgba(0,0,0,.12);
}


hr{
  &.mega{
    margin: 50px;
    // border-color: lighten($primary, 50%);
    // border-bottom: 2px solid lighten($primary, 40%);
  }
}

.underlined{
  position: relative;

  &::after{
    content: "";
    position: absolute;
    bottom: -6px;
    left: 50%;
    width: 0px;
    height: 4px;
    border-radius: 4px;
    background: $primary;
    transition: width 250ms ease-in-out, left 250ms ease-in-out;
  }

  &:hover{
    &::after{
      width: 100%;
      left: 0%;
    }
  }

  &.underlined-primary{
    &::after{
      background: $primary;
    }
  }
  &.underlined-secondary{
    &::after{
      background: $secondary;
    }
  }
  &.underlined-light{
    &::after{
      background: rgba(255,255,255,.25);
    }
  }
}

.headshot-wrapper{
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  padding-bottom: 100%;
}
.headshot{
  position: absolute;
  border-radius: 1000px;
  
  width: 100%;
  // height: 250px;
}

.back-filled{
  position: relative;
  z-index: 1;
  display: inline-block;

  &::after{
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0px;
    top: 0px;
    left: -3px;
    width: 0px;
    background: $gs0;
    border-radius: 1px;
    transition: width 300ms ease-in-out, left 300ms ease-in-out;
  }

  &:hover{
    color: $gs10 !important;
    &::after{
      width: calc(100% + 6px);
      // left: 0%;
    }
  }

  &.back-filled-primary{
    &::after{
      background: $primary;
    }
  }
  &.back-filled-secondary{
    &::after{
      background: $secondary;
    }
  }
  &.back-filled-light{
    &::after{
      background: rgba(255,255,255,.25);
    }
  }
}

.col-extra-padded{
  padding: 40px;
}

.clickable-field-description{
  cursor: pointer;
  &:hover{
    color: $secondary;
  }
}

.small-description{
  color: $gs3;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.col-icon{
  margin-bottom: 25px;
}

.series-of-dots{
  font-size: 10px;
}

.no-margin-bottom{
  margin-bottom: 0;
}
.no-margin-top{
  margin-top: 0;
}
.neg-margin-top-100{
  margin-top: -100px;
}

.row-padded{
  margin-top: 30px;
  margin-bottom: 30px;
}

.row-push-down{
  margin-bottom: 50px;
}
.row-push-down-extra{
  margin-bottom: 100px;
}

.spacer{
  margin-bottom: 200px;
}

.list-center{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .list-item{
    margin-left: 20px;
    margin-right: 20px;
  }
}

.white-container{
  background: $gs10;
  color: $gs2;
  box-shadow: 0 2px 2px rgba(0,0,0,0.08) !important;
  border: 1px solid rgba(0,0,0,0.05);
  border-radius: 5px;
  padding: 32px;
}

.sketch{
  width: 100%;
  height: 100%;
  canvas{
    width: 100%;
    height: 100%;
    outline: none;
    &:focus{
      outline: none;
    }
  }
}

.sketch-list{
  .sketch-thumbnail{
    padding-bottom: 20px;
    display: flex;

    .sketch-thumbnail-number{
      vertical-align: top;
      position: relative;
      top: 9px;
      padding-right: 5px;
      font-size: 12px;
      color: $gs5;
    }
    .sketch-thumbnail-content{
      // display: inline-block;

      .sketch-thumbnail-link{
        color: #000;
        font-weight: 800;
        font-size: 20px;
        margin-bottom: 0px;
        // margin-left: 3px;
        // margin-right: 10px;
      }
      .sketch-thumbnail-description{
        font-weight: 300;

      }
      .sketch-thumbnail-date{
        font-size: 11px;
        font-style: italic; 
        font-weight: 300;
      }
    }
  }
}


.work-list{
  .work-thumbnail{
    margin-bottom: 150px;

    .work-thumbnail-title{
      display: flex;
      align-items: flex-start;
      margin-bottom: 15px;;
      line-height: 1.2;
    }

    .work-thumbnail-image{
      background: $gs9;
      margin-left: 17px;

      width: calc(100% - 17px);
      border-radius: $commonRadius;
      border: 1px solid $gs9;
      overflow: hidden;
      box-shadow: 0px 4px 4px rgba(0,0,0,.06);
      &:hover{
        border: 1px solid $gs8;
        box-shadow: 0px 4px 10px rgba(0,0,0,.1);
      }
    }

    .work-thumbnail-content{
      padding-top: 22px;
    }
    .work-thumbnail-number{
      padding-right: 5px;
      font-size: 12px;
      position: relative;
      top: 13px;
      color: $gs5;
    }

    .work-thumbnail-link{
      color: #000;
      font-weight: 800;
      font-size: 26px;
      margin-bottom: 0px;
    }
    .work-thumbnail-description{
      font-weight: 300;
      margin-bottom: 20px;
    }
    .work-thumbnail-date{
      font-size: 11px;
      font-style: italic; 
      font-weight: 300;
    }
  }
}

@media (min-width: 35em) {
    .card-columns {
        -webkit-column-count: 2 !important;
        -moz-column-count: 2 !important; 
        column-count: 2 !important;
    }
}

@media (min-width: 62em) {
    .card-columns {
        -webkit-column-count: 3 !important;
        -moz-column-count: 3 !important;
        column-count: 3 !important;
    }
}

@media (min-width: 130em) {
    .card-columns {
        -webkit-column-count: 4 !important;
        -moz-column-count: 4 !important;
        column-count: 4 !important;
    }
}

.card{
  padding: 10px;
  img{
    max-width: 100%;
  }
  border: none;
}

canvas {
    image-rendering: optimizeSpeed;             // Older versions of FF
    image-rendering: -moz-crisp-edges;          // FF 6.0+
    image-rendering: -webkit-optimize-contrast; // Webkit (non standard naming)
    image-rendering: -o-crisp-edges;            // OS X & Windows Opera (12.02+)
    image-rendering: crisp-edges;               // Possible future browsers.
    -ms-interpolation-mode: nearest-neighbor;   // IE (non standard naming)
}

.limited-width{
  max-width: 450px;
}

.backdrop-blur{
  backdrop-filter: blur(10px);
}


.article{
  &:hover{
    .article-title{
      background: $gs0;
      color: $gs10;
    }
  }

  .article-tags{
    margin-top: 10px;
    margin-bottom: 10px;
    .article-tag{
      background: $gs9;
      font-size: 11px;
      padding-left: 3px;
      padding-right: 5px;
      margin-right: 5px;
    }
  }
  .article-description{
    position: relative;
    max-height: 300px;
    overflow: hidden;
    font-size: 13px;
    margin-bottom: 10px;
    font-weight: 400;
    h4{
      font-size: 16px;
      margin-bottom: 10px;
    }

    img{
      margin-top: 10px;
      margin-bottom: 10px;
    }
    &:after{
      content:'';
      background: #f00;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100px;
      background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
    }
  }
}

.brand-logo-wrapper{
  padding: 35px;
  filter: grayscale(100%);
  &:hover{
    filter: grayscale(0%);
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


canvas{
  &.tool-output-canvas{
    transition: opacity 200ms ease-in-out;
  }
}

svg{
  &.tool-output-svg{
    width: 100%;
    height: 450px;
  }
}

.align-col-with-input{
  padding-top: 32px;
}

pre{
  padding: 10px;
  background: $gs9;
}