.checkbox {
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  position: relative;
  top: 1px;
  padding-right: 0px;
  padding-left: 0px;
  width: 28px;
  user-select: none;
  margin-right: 5px;
}
.checkbox.disabled {
  cursor: auto;
}
.checkbox.checkbox-true {
  color: #4FBA62;
}
.checkbox.checkbox-false {
  color: #b2bbc3;
}

