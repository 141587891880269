.button {
  position: relative;
  text-decoration: none !important;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: none;
  user-select: none;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 700;
  font-size: 13px;
}
.button .thinking {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.button .button-wrapper {
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 1px;
  border-radius: 5px;
}
.button .button-wrapper.button-space-between {
  justify-content: space-between !important;
}
.button .button-wrapper .button-icon {
  margin-right: -10px;
  opacity: 0.5;
}
.button .button-wrapper .button-display {
  position: relative;
}
.button .button-wrapper.button-dropdown {
  padding-right: 10px;
  padding-left: 13px;
  border-radius: 5px 0px 0px 5px;
}
.button .button-dropdown-arrow {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 25px;
  border-radius: 0px 5px 5px 0px;
  padding-right: 2px;
  border-left: 1px solid rgba(235, 238, 240, 0.5);
  height: 100%;
  border-bottom: none !important;
}
.button .dropdown-menu {
  border-radius: 5px;
}
.button .dropdown-menu .dropdown-item:hover .translucent {
  opacity: 1;
}
.button .dropdown-toggle::after {
  display: none;
}
.button.fixed-width {
  width: 175px;
}
.button:hover {
  text-decoration: none !important;
}
.button .button-thin {
  height: 30px;
}
.button .fa-fw {
  position: relative;
}
.button .button-sm {
  font-size: 10px;
  height: 25px;
}
.button .button-md {
  font-size: 16px;
  border-radius: 5px;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
}
.button .button-lg {
  font-size: 20px;
  height: 50px;
  padding-top: -1px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 5px;
}
.button .button-lg.fixed-width {
  width: 175px;
}
.button:hover {
  text-decoration: none;
}
.button:active {
  text-decoration: none;
}
.button .button-wrapper.button-no-click {
  pointer-events: none;
}
.button .button-wrapper, .button .button-dropdown {
  color: #070809;
  border: 3px solid #070809;
  background: transparent;
  transition: background 100ms ease-in-out, color 100ms ease-in-out;
}
.button .button-wrapper:hover, .button .button-dropdown:hover {
  background: #070809;
  color: white;
}
.button .button-wrapper.button-success, .button .button-dropdown.button-success {
  color: white;
  background-color: #4FBA62;
}
.button .button-wrapper.button-success:hover, .button .button-dropdown.button-success:hover {
  color: white;
  background-color: #3c9a4d;
}
.button .button-wrapper.button-success:active, .button .button-dropdown.button-success:active {
  color: white;
  background-color: #2e753b;
}
.button .button-wrapper.button-danger, .button .button-dropdown.button-danger {
  color: white;
  background-color: #D24128;
}
.button .button-wrapper.button-danger:hover, .button .button-dropdown.button-danger:hover {
  color: white;
  background-color: #a73420;
}
.button .button-wrapper.button-danger:active, .button .button-dropdown.button-danger:active {
  color: white;
  background-color: #7c2618;
}
.button .button-wrapper.button-primary, .button .button-dropdown.button-primary {
  color: white;
  background-color: #F5F749;
}
.button .button-wrapper.button-primary:hover, .button .button-dropdown.button-primary:hover {
  color: white;
  background-color: #f2f518;
}
.button .button-wrapper.button-primary:active, .button .button-dropdown.button-primary:active {
  color: white;
  background-color: #cfd109;
}
.button .button-wrapper.button-gs5, .button .button-dropdown.button-gs5 {
  color: #1d2226;
  background-color: #798896;
}
.button .button-wrapper.button-gs5:hover, .button .button-dropdown.button-gs5:hover {
  color: white;
  background-color: #606e7c;
}
.button .button-wrapper.button-gs5:active, .button .button-dropdown.button-gs5:active {
  color: white;
  background-color: #4a555f;
}
.button .button-wrapper.button-secondary, .button .button-dropdown.button-secondary {
  color: white;
  background-color: #466AA4;
}
.button .button-wrapper.button-secondary:hover, .button .button-dropdown.button-secondary:hover {
  color: white;
  background-color: #375380;
}
.button .button-wrapper.button-secondary:active, .button .button-dropdown.button-secondary:active {
  color: white;
  background-color: #273c5d;
}
.button .button-wrapper.button-hover-text-success:hover, .button .button-dropdown.button-hover-text-success:hover {
  color: #4FBA62 !important;
}
.button .button-wrapper.button-hover-text-primary:hover, .button .button-dropdown.button-hover-text-primary:hover {
  color: #F5F749 !important;
}
.button .button-wrapper.button-hover-text-secondary:hover, .button .button-dropdown.button-hover-text-secondary:hover {
  color: #466AA4 !important;
}
.button .button-wrapper.button-warning, .button .button-dropdown.button-warning {
  color: white;
  background-color: #faaa35;
}
.button .button-wrapper.button-warning:hover, .button .button-dropdown.button-warning:hover {
  color: white;
  background-color: #f69506;
}
.button .button-wrapper.button-warning:active, .button .button-dropdown.button-warning:active {
  color: white;
  background-color: #c47605;
}
.button .button-wrapper.button-orange, .button .button-dropdown.button-orange {
  color: white;
  background-color: #faaa35;
}
.button .button-wrapper.button-orange:hover, .button .button-dropdown.button-orange:hover {
  color: white;
  background-color: #f69506;
}
.button .button-wrapper.button-orange:active, .button .button-dropdown.button-orange:active {
  color: white;
  background-color: #c47605;
}
.button .button-wrapper.button-white, .button .button-dropdown.button-white {
  color: #343b42;
  background-color: white;
}
.button .button-wrapper.button-white:hover, .button .button-dropdown.button-white:hover {
  color: #466AA4;
  background-color: white;
}
.button .button-wrapper.button-white:active, .button .button-dropdown.button-white:active {
  color: #273c5d;
  background-color: white;
}
.button.no-pad-right {
  padding-right: 0px !important;
}
.button.button-disabled {
  opacity: 0.5;
  pointer-events: none !important;
}
.button.button-thinking {
  opacity: 0.75;
  pointer-events: none;
}
.button .dropdown {
  position: inherit;
}
.button .dropdown .dropdown-menu {
  margin-top: -2px;
  position: inherit;
  z-index: 1000;
  width: 300px;
  border-radius: 10px 0px 10px 10px;
}
.button .dropdown .dropdown-menu .dropdown-item:hover .translucent {
  opacity: 1;
}
.button .dropdown-toggle::after {
  display: none;
}

