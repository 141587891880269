html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background: white;
  font-variant-ligatures: no-common-ligatures;
  -webkit-font-variant-ligatures: no-common-ligatures;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}
#root > div {
  position: relative;
  min-height: 100%;
}

.body {
  height: 100%;
}
.body .body-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.body .body-wrapper .body-stretch {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.body-wrapper {
  height: 100%;
}

h1, h2, h3, h4, h5 {
  user-select: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

h5 {
  font-weight: 700;
}

h1 {
  font-size: 3.5rem;
  font-weight: 700;
}

a {
  color: #070809;
  font-weight: 700;
  text-decoration: none !important;
  transition: color 200ms ease-in-out;
  cursor: pointer;
}
a:hover {
  color: #070809;
}
a.no-color {
  color: inherit;
}

.clickable {
  cursor: pointer;
}

.oscilate-vertical {
  position: relative;
  animation: oscilate-vertical 1.5s infinite;
}

@keyframes oscilate-vertical {
  0% {
    top: -2px;
  }
  50% {
    top: 2px;
  }
  100% {
    top: -2px;
  }
}
.jumbotron {
  background: transparent;
  margin-bottom: 0px;
  position: relative;
  border-radius: 20px;
}
.jumbotron .jumbotron-popout {
  position: absolute;
  top: -100px;
  background: white;
  border-radius: 2px;
  padding: 40px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  color: #1d2226;
}
.jumbotron .jumbotron-header-verbs {
  font-size: 11px;
  font-weight: 700;
  margin-bottom: 5px;
}
.jumbotron.jumbotron-mega-pad {
  padding-top: 125px;
  padding-bottom: 125px;
}
.jumbotron h1 {
  margin-bottom: 30px;
}
.jumbotron h3 {
  margin-bottom: 10px;
}
.jumbotron.jumbotron-white {
  background: #ebeef0;
}
.jumbotron.jumbotron-clear {
  background: transparent;
}
.jumbotron.jumbotron-primary {
  background: #F5F749;
  color: white;
}
.jumbotron.jumbotron-secondary {
  background: #466AA4;
  color: white;
}
.jumbotron.jumbotron-light {
  background: #ebeef0;
}

.text-heavy {
  font-weight: 700;
}

.text-primary {
  color: #F5F749;
}

.text-secondary {
  color: #466AA4;
}

.text-lg {
  font-size: 130%;
}

.text-sm {
  font-size: 70%;
}

.text-300 {
  font-weight: 300;
}

.text-700 {
  font-weight: 700;
}

.text-900 {
  font-weight: 900;
}

.text-medium-body {
  font-size: 13px;
  font-weight: 400;
}

.translucent-hover {
  transition: opacity 200ms ease-in-out;
  opacity: 1;
}
.translucent-hover:hover {
  opacity: 0.5;
}

.translucent {
  opacity: 0.5;
}

.semi-translucent {
  opacity: 0.85;
}

.vertical-divider {
  font-size: 12px;
  opacity: 0.5;
  position: relative;
  top: -2px;
  padding-left: 5px;
  padding-right: 5px;
}

.screen-height-container {
  position: relative;
  display: block;
  width: 100%;
  min-height: 100vh;
}
.screen-height-container.screen-height-container-with-peek {
  min-height: calc(100vh - 20px) !important;
}
.screen-height-container .shc-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.screen-height-container .shc-background.shc-background-muted > * {
  filter: blur(10px);
}
.screen-height-container .shc-background .shc-carousel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-attachment: fixed;
  opacity: 0;
  transition: opacity 2000ms ease-in-out;
}
.screen-height-container .shc-background .shc-carousel.shc-carousel-current {
  opacity: 1;
}
.screen-height-container .shc-background .shc-background-gradient {
  position: absolute !important;
  top: 0px !important;
  height: 30% !important;
  width: 100% !important;
  min-height: 90px !important;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)) !important;
}
.screen-height-container .shc-content {
  position: relative;
  z-index: 10;
  height: 100vh;
  display: block;
}
.screen-height-container .shc-content.shc-content-center {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.screen-height-container .shc-top-left-button {
  position: absolute;
  top: 20px;
  left: 20px;
}
.screen-height-container .shc-top-right-button {
  position: absolute;
  top: 20px;
  right: 20px;
}
.screen-height-container .shc-content-split {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  /* Smartphones (portrait) ----------- */
}
@media only screen and (max-width: 720px) {
  .screen-height-container .shc-content-split {
    display: block;
  }
}
.screen-height-container .shc-content-split .shc-content-half {
  flex: 0.5;
  position: relative;
}
.screen-height-container .shc-bottom-content {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 11;
  pointer-events: none;
}
.screen-height-container .shc-top-center-progress {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  pointer-events: none;
  display: flex;
  justify-content: space-around;
  padding-top: 28px;
}
.screen-height-container .shc-top-center-progress .fa-circle {
  color: #F5F749;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
}

p.push-down {
  margin-bottom: 30px;
}

.shc-padded {
  padding-top: 50px;
  padding-bottom: 50px;
}

.shc-padded-top {
  padding-top: 50px;
}

.shc-transparent {
  background: none;
}

.shc-primary {
  color: white;
  background: #F5F749;
}

.shc-secondary {
  color: white;
  background: #466AA4;
}

.shc-third {
  color: white;
  background: #F24236;
}

.shc-success {
  color: white;
  background: #4FBA62;
}

.shc-gs1 {
  color: white;
  background: #1d2226;
}

.shc-grey {
  color: #1d2226;
  background: #b2bbc3;
}

.shc-gs10 {
  color: #1d2226;
  background: white;
}

.page-section {
  padding-top: 50px;
  padding-bottom: 50px;
}

.bg-primary-light {
  background: white;
}

.bg-white-home-text {
  background: rgba(255, 255, 255, 0.9) !important;
  padding: 8px 10px;
  border-radius: 2px;
}

.bg-primary {
  background: #F5F749 !important;
}

.bg-secondary {
  background: #466AA4 !important;
}

.flex-split {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.list-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.list-left .list-item-grow {
  flex-grow: 1;
}
.list-left .list-item {
  margin-right: 20px;
}
.list-left .list-item:last-child {
  margin-right: 0px;
}

.list-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.list-right .list-item {
  margin-left: 20px;
}
.list-right .list-item:first-child {
  margin-left: 0px;
}

.rounded-image {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
}

hr.mega {
  margin: 50px;
}

.underlined {
  position: relative;
}
.underlined::after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 50%;
  width: 0px;
  height: 4px;
  border-radius: 4px;
  background: #F5F749;
  transition: width 250ms ease-in-out, left 250ms ease-in-out;
}
.underlined:hover::after {
  width: 100%;
  left: 0%;
}
.underlined.underlined-primary::after {
  background: #F5F749;
}
.underlined.underlined-secondary::after {
  background: #466AA4;
}
.underlined.underlined-light::after {
  background: rgba(255, 255, 255, 0.25);
}

.headshot-wrapper {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  padding-bottom: 100%;
}

.headshot {
  position: absolute;
  border-radius: 1000px;
  width: 100%;
}

.back-filled {
  position: relative;
  z-index: 1;
  display: inline-block;
}
.back-filled::after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0px;
  top: 0px;
  left: -3px;
  width: 0px;
  background: #070809;
  border-radius: 1px;
  transition: width 300ms ease-in-out, left 300ms ease-in-out;
}
.back-filled:hover {
  color: white !important;
}
.back-filled:hover::after {
  width: calc(100% + 6px);
}
.back-filled.back-filled-primary::after {
  background: #F5F749;
}
.back-filled.back-filled-secondary::after {
  background: #466AA4;
}
.back-filled.back-filled-light::after {
  background: rgba(255, 255, 255, 0.25);
}

.col-extra-padded {
  padding: 40px;
}

.clickable-field-description {
  cursor: pointer;
}
.clickable-field-description:hover {
  color: #466AA4;
}

.small-description {
  color: #4a555f;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.col-icon {
  margin-bottom: 25px;
}

.series-of-dots {
  font-size: 10px;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-margin-top {
  margin-top: 0;
}

.neg-margin-top-100 {
  margin-top: -100px;
}

.row-padded {
  margin-top: 30px;
  margin-bottom: 30px;
}

.row-push-down {
  margin-bottom: 50px;
}

.row-push-down-extra {
  margin-bottom: 100px;
}

.spacer {
  margin-bottom: 200px;
}

.list-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.list-center .list-item {
  margin-left: 20px;
  margin-right: 20px;
}

.white-container {
  background: white;
  color: #343b42;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08) !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 32px;
}

.sketch {
  width: 100%;
  height: 100%;
}
.sketch canvas {
  width: 100%;
  height: 100%;
  outline: none;
}
.sketch canvas:focus {
  outline: none;
}

.sketch-list .sketch-thumbnail {
  padding-bottom: 20px;
  display: flex;
}
.sketch-list .sketch-thumbnail .sketch-thumbnail-number {
  vertical-align: top;
  position: relative;
  top: 9px;
  padding-right: 5px;
  font-size: 12px;
  color: #798896;
}
.sketch-list .sketch-thumbnail .sketch-thumbnail-content .sketch-thumbnail-link {
  color: #000;
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 0px;
}
.sketch-list .sketch-thumbnail .sketch-thumbnail-content .sketch-thumbnail-description {
  font-weight: 300;
}
.sketch-list .sketch-thumbnail .sketch-thumbnail-content .sketch-thumbnail-date {
  font-size: 11px;
  font-style: italic;
  font-weight: 300;
}

.work-list .work-thumbnail {
  margin-bottom: 150px;
}
.work-list .work-thumbnail .work-thumbnail-title {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  line-height: 1.2;
}
.work-list .work-thumbnail .work-thumbnail-image {
  background: #ebeef0;
  margin-left: 17px;
  width: calc(100% - 17px);
  border-radius: 2px;
  border: 1px solid #ebeef0;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
}
.work-list .work-thumbnail .work-thumbnail-image:hover {
  border: 1px solid #cfd4d9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.work-list .work-thumbnail .work-thumbnail-content {
  padding-top: 22px;
}
.work-list .work-thumbnail .work-thumbnail-number {
  padding-right: 5px;
  font-size: 12px;
  position: relative;
  top: 13px;
  color: #798896;
}
.work-list .work-thumbnail .work-thumbnail-link {
  color: #000;
  font-weight: 800;
  font-size: 26px;
  margin-bottom: 0px;
}
.work-list .work-thumbnail .work-thumbnail-description {
  font-weight: 300;
  margin-bottom: 20px;
}
.work-list .work-thumbnail .work-thumbnail-date {
  font-size: 11px;
  font-style: italic;
  font-weight: 300;
}

@media (min-width: 35em) {
  .card-columns {
    -webkit-column-count: 2 !important;
    -moz-column-count: 2 !important;
    column-count: 2 !important;
  }
}
@media (min-width: 62em) {
  .card-columns {
    -webkit-column-count: 3 !important;
    -moz-column-count: 3 !important;
    column-count: 3 !important;
  }
}
@media (min-width: 130em) {
  .card-columns {
    -webkit-column-count: 4 !important;
    -moz-column-count: 4 !important;
    column-count: 4 !important;
  }
}
.card {
  padding: 10px;
  border: none;
}
.card img {
  max-width: 100%;
}

canvas {
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

.limited-width {
  max-width: 450px;
}

.backdrop-blur {
  backdrop-filter: blur(10px);
}

.article:hover .article-title {
  background: #070809;
  color: white;
}
.article .article-tags {
  margin-top: 10px;
  margin-bottom: 10px;
}
.article .article-tags .article-tag {
  background: #ebeef0;
  font-size: 11px;
  padding-left: 3px;
  padding-right: 5px;
  margin-right: 5px;
}
.article .article-description {
  position: relative;
  max-height: 300px;
  overflow: hidden;
  font-size: 13px;
  margin-bottom: 10px;
  font-weight: 400;
}
.article .article-description h4 {
  font-size: 16px;
  margin-bottom: 10px;
}
.article .article-description img {
  margin-top: 10px;
  margin-bottom: 10px;
}
.article .article-description:after {
  content: "";
  background: #f00;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(255, 255, 255) 100%);
}

.brand-logo-wrapper {
  padding: 35px;
  filter: grayscale(100%);
}
.brand-logo-wrapper:hover {
  filter: grayscale(0%);
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

canvas.tool-output-canvas {
  transition: opacity 200ms ease-in-out;
}

svg.tool-output-svg {
  width: 100%;
  height: 450px;
}

.align-col-with-input {
  padding-top: 32px;
}

pre {
  padding: 10px;
  background: #ebeef0;
}

