@import '../../styles/_variables.scss';

$arrowSize: 6px;
$toolipBorderColor: $gs7;
$triangleBorderColor: darken($toolipBorderColor, 25%);
$tooltipBackgroundColor: $gs10;
$tooltipTextColor: $gs1;


.pc-tooltip-wrapper{
  position: absolute;
  z-index: 9999;
  pointer-events: none;
  overflow: hidden;

  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  .pc-tooltip-inner{
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.pc-tooltip{
  position: absolute;

  opacity: 0;
  transition: opacity 150ms ease-out, 
              margin-top 250ms ease-out,
              margin-bottom 250ms ease-out,
              margin-left 250ms ease-out,
              margin-right 250ms ease-out;

  margin-top: 0px; 
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;

  &.pc-tooltip-top{
    margin-bottom: -20px;
  }

  &.pc-tooltip-bottom{
    margin-top: -20px;
  }

  &.pc-tooltip-left{
    margin-right: -20px;
  }

  &.pc-tooltip-right{
    margin-left: -20px;
  }

  &.pc-tooltip-show{
    opacity: 1;

    transition: opacity 250ms ease-out, 
            margin-top 250ms ease-out,
            margin-bottom 250ms ease-out,
            margin-left 250ms ease-out,
            margin-right 250ms ease-out;

    &.pc-tooltip-top{
      margin-bottom: 0px;
    }
    &.pc-tooltip-bottom{
      margin-top: 0px;
    }
    &.pc-tooltip-left{
      margin-right: 0px;
    }
    &.pc-tooltip-right{
      margin-left: 0px;
    }
  }

  .pc-tooltip-content{
    position: relative;
    display: inline-block;
    background: $tooltipBackgroundColor;
    color: $tooltipTextColor;
    font-size: 13px;
    margin: 0;
    padding: 10px;
    padding-left: 13px;
    padding-right: 13px;
    border-radius: 5px;
    box-shadow: 0px 0px 3px rgba(0,0,0,.2);
    border: 1px solid $toolipBorderColor;

    .tooltip-title{
      font-size: 14px;
      font-weight: 700;
    }

    &.pc-tooltip-content-top{
      margin-left: -50%;
      
      &:before{
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;

        border-left: ($arrowSize + 1) solid transparent;
        border-right: ($arrowSize + 1) solid transparent;
        border-top:($arrowSize + 1) solid $triangleBorderColor;
        bottom: -($arrowSize + 1);
        left: 50%;
        margin-left: -($arrowSize + 1);
      }
      &:after{
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;

        border-left: $arrowSize solid transparent;
        border-right: $arrowSize solid transparent;
        border-top:$arrowSize solid $tooltipBackgroundColor;
        bottom: -$arrowSize;
        left: 50%;
        margin-left: -$arrowSize;
      }
    }
    &.pc-tooltip-content-bottom{
      margin-left: -50%;
      &:before{
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;

        border-left: ($arrowSize + 1) solid transparent;
        border-right: ($arrowSize + 1) solid transparent;
        border-bottom: ($arrowSize + 1) solid $triangleBorderColor;
        top: -($arrowSize + 1);
        left: 50%;
        margin-left: -($arrowSize + 1);
      }
      &:after{
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;

        border-left: $arrowSize solid transparent;
        border-right: $arrowSize solid transparent;
        border-bottom: $arrowSize solid $tooltipBackgroundColor;
        top: -$arrowSize;
        left: 50%;
        margin-left: -$arrowSize;
      }
    }
    &.pc-tooltip-content-left{
      margin-left: -100%;
      margin-top: -50%;
      &:before{
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;

        border-top: ($arrowSize + 1) solid transparent;
        border-bottom: ($arrowSize + 1) solid transparent;
        border-left:($arrowSize + 1) solid $triangleBorderColor;
        right: -($arrowSize + 1);
        top: 50%;
        margin-top: -($arrowSize + 1);
      }
      &:after{
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;

        border-top: $arrowSize solid transparent;
        border-bottom: $arrowSize solid transparent;
        border-left:$arrowSize solid $tooltipBackgroundColor;
        right: -$arrowSize;
        top: 50%;
        margin-top: -$arrowSize;
      }
    }
    &.pc-tooltip-content-right{
      margin-top: -50%;
      &:before{
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;

        border-top: ($arrowSize + 1) solid transparent;
        border-bottom: ($arrowSize + 1) solid transparent;
        border-right:($arrowSize + 1) solid $triangleBorderColor;
        left: -($arrowSize + 1);
        top: 50%;
        margin-top: -($arrowSize + 1);
      }
      &:after {
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;

        border-top: $arrowSize solid transparent;
        border-bottom: $arrowSize solid transparent;
        border-right:$arrowSize solid $tooltipBackgroundColor;
        left: -$arrowSize;
        top: 50%;
        margin-top: -$arrowSize;
    }
    }
  }
}
